import React, { useEffect } from "react";
import FileSaver from "file-saver";
import * as XLSX from 'xlsx'
import {
    Button,  
  } from "@vwfs-bronson/bronson-react";

  export const ExportCSV = ({ csvData, fileName, fetchdataCSV}) => {
  
    const fileType ="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension =".xlsx";
    const headersFile = ["RequestDate", "Service", "Method", "User", "Application", "Date", "Request", "Response"]
    let excelData=[];
 
    const exportToCSV = (csvData, fileName) => {   
      if(csvData!=null && csvData!=undefined){        
        excelData= csvData.LogItems.map(objeto=>{         
           const formatedCSVData ={...objeto};
           delete formatedCSVData.checked;
           delete formatedCSVData.ExpirationTime;
           delete formatedCSVData.Action;
           delete formatedCSVData.Path;              
           return formatedCSVData;              
      });
     }
    const ws = XLSX.utils.json_to_sheet(excelData, {header: headersFile});
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    
  };
  useEffect(() => {
   if(csvData!=null && csvData!=undefined){
    exportToCSV(csvData, fileName);
   }
 
  }, [csvData]);

  async function fetchDataCSVEvent(){
    await fetchdataCSV();    
  }
    return (
      <Button
        type="submit"
        variant="warning"
        onClick={()=> fetchDataCSVEvent()}
                
      >
        Export XLSX
      </Button>
    );
  };
  

  