import React, { useEffect, useState } from "react";
import {
  Fieldset,
  Form,
  FormHeading,
  Layout,
  Card,
  Badge,
  FormField,
  Input,
  Button,
  ButtonContainer,
} from "@vwfs-bronson/bronson-react";
import Graph from "../components/Graph";
import MonitorContext from "../contexts/MonitorContextProvider";
import { useContext } from "react";

export const MonitorPage = () => {
  const { search, service } = useContext(MonitorContext);
  const [currentService, setCurrentService] = useState(service);
  const [lista, setLista] = useState([]);
  const [totalServices, setTotalServices] = useState([]);
  const [OkServices, setOkServices] = useState("0");
  const [KoServices, setKoServices] = useState("0");

  //const [find, setFind] = useState([]);
  const [input, setInput] = useState("");

  useEffect(() => {
    //Quitar si no se quiere hacer un fetch del servicio al init del componente
    search({ ServiceName: "" });
  }, []);

  useEffect(() => {
    if (service) {
      setCurrentService(service);
    }
  }, [service]);

  useEffect(() => {
    if (
      currentService !== null &&
      currentService !== undefined &&
      currentService.length !== 0
    ) {
      const serviciosUnicos = [
        ...new Set(
          currentService.map((currentService) => currentService.ServiceName)
        ),
      ];
      setLista(serviciosUnicos);
    }
  }, [currentService]);

  useEffect(() => {
    setTotalServices(lista.length === 0 ? "0" : lista.length);
    let ok = 0;
    let ko = 0;
    if (
      currentService !== null &&
      currentService !== undefined &&
      currentService.length !== 0
    ) {
      lista.forEach((serviceName) => {
        const serviciosFiltrados = currentService.filter(
          (item) => item.ServiceName === serviceName
        );
        if (serviciosFiltrados[0].hasOwnProperty('200')) {
          ok++;
        } else {
          ko++;
        }
      });
    }
    setKoServices(ko === 0 ? "0" : ko);
    setOkServices(ok === 0 ? "0" : ok);
  }, [lista]);

  const Buscar = async (e) => {
    search({
      ServiceName: input,
    });
    setLista([]);
    setCurrentService([]);
  };
  function handleSubmit(e) {
    e.preventDefault();
  }

  const FillCounters = async (e) => {
    if (currentService !== null && currentService !== undefined) {
    }
  };

  return (
    <React.Fragment>
      <Layout center>
        <Layout.Item default="1/1" s="1/1">
          <Card element="article">
            <Form floatingLabel onSubmit={handleSubmit}>
              <Fieldset>
                <FormHeading>Monitoreo de Servicios</FormHeading>
                <Fieldset.Row>
                  <Layout right>
                    <Layout.Item default="1/5" s="1/1">
                      <div
                        style={{
                          textAlign: "center",
                          border: "solid",
                          borderWidth: 1,
                        }}
                      >
                        <div>
                          Total de Servicios: <Badge>{totalServices}</Badge>
                        </div>
                        <div>
                          Total de inactivos:{" "}
                          <Badge modifier="error">{KoServices}</Badge>
                        </div>
                        <div>
                          Total de activos:{" "}
                          <Badge modifier="success">{OkServices}</Badge>
                        </div>
                      </div>
                    </Layout.Item>
                  </Layout>
                </Fieldset.Row>
                <Fieldset.Row>
                  <Layout center>
                    <Layout.Item>
                      <FormField
                        labelForId="findLabel"
                        labelText=""
                        type="input"
                      >
                        <Input
                          id="findInput"
                          onChange={(e) => setInput(e.target.value)}
                          placeholder="Buscar Servicios"
                        />
                      </FormField>
                    </Layout.Item>
                    <Layout.Item>
                      <ButtonContainer center>
                        <Button onClick={Buscar}>Buscar</Button>
                      </ButtonContainer>
                    </Layout.Item>
                  </Layout>
                </Fieldset.Row>
              </Fieldset>
            </Form>
          </Card>
        </Layout.Item>
      </Layout>
      <Fieldset>
        <Layout center>
          {lista != null &&
            lista !== undefined &&
            lista.map((res, index) => (
              <Layout.Item key={index} default="1/1" s="1/1">
                <Graph
                  key={index}
                  nameProp={res}
                  dataProp={currentService.filter((x) => x.ServiceName === res)}
                />
              </Layout.Item>
            ))}
        </Layout>
      </Fieldset>
    </React.Fragment>
  );
};
